import Q from "q";
import moment from "moment";

// 返回旅游公告类型枚举
import web_common_TourismMall_getNoticeTypeEnum from "@/lib/data-service/default/web_common_TourismMall_getNoticeTypeEnum";
// 列表
import web_common_TourismMall_noticeList from "@/lib/data-service/default/web_common_TourismMall_noticeList";

export default {
    data() {
        return {
            params: {
                noticeType: 1, // 公告类型   1：最新公告    2：重要公告   3：系统公告

                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            loading: false,
            noticeTypeList: [],
            noticeList: []
        }
    },
    methods: {
        get_notice_type() {
            const __this = this;
            __this.params.noticeType = __this.$route.query.noticeType ? parseInt(__this.$route.query.noticeType) : 1;
            web_common_TourismMall_getNoticeTypeEnum().then((res) => {
                __this.noticeTypeList = res.types
            })
        },
        get_notice_list() {
            const __this = this;
            Q.when().then(() => {
                __this.loading = true;
                return web_common_TourismMall_noticeList(__this.params);
            }).then((res) => {
                __this.noticeList = res.pageResult.pageData;
                __this.params.totalCount = res.pageResult.totalCount;
            }).finally(() => {
                __this.loading = false;
            });
        },
        handleSizeChange(size) {
            const __this = this;
            __this.params.currentPage = 1;
            __this.params.pageSize = size;
            __this.get_notice_list()
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.params.currentPage = page;
            __this.get_notice_list()
        },
        to_notice_list(row) {
            const __this = this;
            __this.$router.push({
                name: 'distributor-notice-list',
                query: {
                    noticeType: row.type
                }
            })
        },
        to_detail(row) {
            const __this = this;
            __this.$router.push({
                name: 'distributor-notice-detail',
                query: {
                    noticeType: __this.params.noticeType,
                    noticeId: row.id
                }
            })
        }
    },
    created() {
        const __this = this;
        __this.get_notice_type();
        __this.get_notice_list();
    },
    filters: {
        formatDate(val) {
            return val === '' ? '' : moment(val).format('YYYY-MM-DD HH:mm');
        },
        formatNoticeTitle(val) {
            const param = parseInt(val);
            switch (param) {
                case 1:
                    return '最新公告';
                    break;
                case 2:
                    return '重要公告';
                    break;
                case 3:
                    return '系统公告';
                    break;
            }
        },
    },
    watch: {
        $route: {
            handler(val, oldVal){
                const __this = this;
                __this.params.noticeType = val.query.noticeType;
                __this.get_notice_list()
            },
            // 深度观察监听
            deep: true
        }
    }
}
